@font-face {
  font-family: "Poppins-Black";
  src: local("Poppins-Black"),
    url(./assets/fonts/poppins/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-BlackItalic";
  src: local("Poppins-BlackItalic"),
    url(./assets/fonts/poppins/Poppins-BlackItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-BoldItalic";
  src: local("Poppins-BoldItalic"),
    url(./assets/fonts/poppins/Poppins-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: local("Poppins-ExtraBold"),
    url(./assets/fonts/poppins/Poppins-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBoldItalic";
  src: local("Poppins-ExtraBoldItalic"),
    url(./assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLight";
  src: local("Poppins-ExtraLight"),
    url(./assets/fonts/poppins/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraLightItalic";
  src: local("Poppins-ExtraLightItalic"),
    url(./assets/fonts/poppins/Poppins-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Italic";
  src: local("Poppins-Italic"),
    url(./assets/fonts/poppins/Poppins-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Light";
  src: local("Poppins-Light"),
    url(./assets/fonts/poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-LightItalic";
  src: local("Poppins-LightItalic"),
    url(./assets/fonts/poppins/Poppins-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-MediumItalic";
  src: local("Poppins-MediumItalic"),
    url(./assets/fonts/poppins/Poppins-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./assets/fonts/poppins/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBoldItalic";
  src: local("Poppins-SemiBoldItalic"),
    url(./assets/fonts/poppins/Poppins-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins-Thin"),
    url(./assets/fonts/poppins/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-ThinItalic";
  src: local("Poppins-ThinItalic"),
    url(./assets/fonts/poppins/Poppins-ThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Sahel-Sahel";
  src: local("Sahel-Sahel"),
    url(./assets/fonts/sahel/Sahel.ttf) format("truetype");
}



body {
  margin: 0;
  font-family: "Poppins-ThinItalic", "Poppins-Thin", "Poppins-ExtraLight", "Poppins-ExtraLightItalic",
    "Poppins-Light", "Poppins-LightItalic", "Poppins-Regular", "Poppins-Italic",
    "Poppins-Medium", "Poppins-MediumItalic", "Poppins-SemiBold", "Poppins-SemiBoldItalic",
    "Poppins-Bold", "Poppins-BoldItalic", "Poppins-ExtraBold", "Poppins-ExtraBoldItalic",
    "Poppins-Black", "Poppins-BlackItalic", "Sahel-Sahel";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins-ThinItalic", "Poppins-Thin", "Poppins-ExtraLight", "Poppins-ExtraLightItalic",
    "Poppins-Light", "Poppins-LightItalic", "Poppins-Regular", "Poppins-Italic",
    "Poppins-Medium", "Poppins-MediumItalic", "Poppins-SemiBold", "Poppins-SemiBoldItalic",
    "Poppins-Bold", "Poppins-BoldItalic", "Poppins-ExtraBold", "Poppins-ExtraBoldItalic",
    "Poppins-Black", "Poppins-BlackItalic", "Sahel-Sahel",
    monospace;
}

@media (min-width: 0px) {
  html {
    font-size: 7.58px;
  }
}
@media (min-width: 600px) {
  html {
    font-size: 9.62px;
  }
}
@media (min-width: 900px) {
  html {
    font-size: 11.66px;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 13.5px;
  }
}
@media (min-width: 1536px) {
  html {
    font-size: 14.88px;
  }
}


h1 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

h2 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

h3 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

h4 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

h5 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

h6 {
  margin: 0;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}

button {
  font-family: "Poppins-Regular";
  font-size: 1.12rem;
  margin: 0px;
  line-height: 2rem;
  text-transform: capitalize;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0%;
  border: unset;
  padding-inline: unset;
  padding-block: unset;
  background-color: unset;
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;

}

legend {
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-block-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

input {
  border: none;
  outline: none;
  font-family: "Poppins-Regular";
  font-size: 1.12rem;
  line-height: 1.9rem;
  cursor: pointer;
  background: transparent;
  padding-block: 0;
  padding-inline: 0;
}

div {
  font-family: "Poppins-Regular";
  font-size: 1.12rem;
  line-height: 2rem;
}

p {
  font-family: "Poppins-Regular";
  font-size: 0.8rem;
  margin: 0px;
  line-height: 1.2rem;
  text-transform: capitalize;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0%;
}

h6 {
  margin: 0;
  font-size: 0.96rem;
  line-height: 1.3rem;
  font-family: "Poppins-Regular";
  font-weight: 100;
}